import React from 'react'
import { UserManager } from 'oidc-client';

function SignInCallBack() {
    new UserManager({response_mode:"query"}).signoutRedirectCallback()
        .then(function() {
            window.location = "/";
        })
        .catch(function(e) {
            console.error(e);
        });
        return (
            <></>
        )
}

export default SignInCallBack;