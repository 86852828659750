import React, { useEffect, useState, useContext } from "react";
import styled from "@emotion/styled";
//import Table from "../../components/Table";
import { NavLink } from "react-router-dom";
import { Helmet } from "react-helmet-async";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { EnvironmentContext } from "../../contexts/EnvironmentContext";
import { DataGrid } from '@mui/x-data-grid';
import axios from "../../utils/axios";
import Loader from "../../components/Loader";
import { Edit2, Trash2 } from "react-feather";

import {
   CardContent,
   Grid,
   Link,
   Breadcrumbs as MuiBreadcrumbs,
   Card as MuiCard,
   Divider as MuiDivider,
   Typography,
   Button,
   Dialog,
   DialogTitle,
   DialogContent,
   DialogContentText,
   DialogActions,
   CardHeader,
   Paper,
   Chip as MuiChip,
   Stack,
} from "@mui/material";
import { spacing } from "@mui/system";

const Card = styled(MuiCard)(spacing);
const Divider = styled(MuiDivider)(spacing);
const Breadcrumbs = styled(MuiBreadcrumbs)(spacing);
const ParametersTable = styled.table`
  width: 100%;
  font-size: 12px;
  
  td:last-child {
    padding: 2px 0;
    color: ${(props) =>
      props.theme.palette[props.color ? props.color : "primary"].light};
  }
`

const Chip = styled(MuiChip)`
  height: 20px;
  padding: 4px 0;
  font-size: 90%;
  background-color: ${(props) =>
    props.theme.palette[props.color ? props.color : "primary"].light};
  color: ${(props) => props.theme.palette.common.white};
`;

function ParametersCard() {
   const { t } = useTranslation();

   return (
      <Card mb={6}>
         <CardContent>
            <Typography variant="h6" gutterBottom>
               {t("UsageParameters")}
            </Typography>
            <ParametersTable>
               <tbody>
                  <tr>
                     <td>Host</td>
                     <td>smtp.iplus.app</td>
                  </tr>
                  <tr>
                     <td>Port</td>
                     <td>25000</td>
                  </tr>
                  <tr>
                     <td>User</td>
                     <td>"{t("MyUserOf")} iPlus Relay"</td>
                  </tr>
                  <tr>
                     <td>Password</td>
                     <td>"{t("My")} API Key"</td>
                  </tr>
               </tbody>
            </ParametersTable>
         </CardContent>
      </Card>
   );
}

function EnlacesCard() {
   const { t } = useTranslation();

   return (
      <Card mb={6}>
         <CardContent>
            <Typography variant="h6" gutterBottom>
               {t("Links")}
            </Typography>
            <Typography variant="caption" display="block" gutterBottom>
               <Link component={NavLink} to="/settings/api-keys">
                  API Keys
               </Link>
            </Typography>
            <Typography variant="caption" display="block" gutterBottom>
               <Link component={NavLink} to="/account/billing">
                  {t("Billing")}
               </Link>
            </Typography>
            <Typography variant="caption" display="block" gutterBottom>
               <Link component={NavLink} to="/transactions/sms-sent">
                  SMS {t("Sent")}
               </Link>
            </Typography>
            <Typography variant="caption" display="block" gutterBottom>
               <Link component={NavLink} to="/transactions/push-notifications-sent">
                  Push Notifications {t("Sent")}
               </Link>
            </Typography>
         </CardContent>
      </Card>
   );
}


function KeysCard({ columns, tableBody }) {
   const { t } = useTranslation();
   //const tableHeader = [t("Status"), "Key", t("Comments"), t("CreatedIn"), t("Action")];
   //const bodyKeys = ["enabled", "id", "name", "createdAt", "actions:Delete"];

   // const actions = {
   //    "Delete": deleteApikey
   // }

   return (
      //<Table title={t("Api Keys")} tableHeader={tableHeader} tableBody={tableBody} bodyKeys={bodyKeys} handlers={actions} />
      <Card mb={6}>
         <CardHeader title={t("Api Keys")} />
         <Paper>
            <div style={{ height: 400, width: '100%' }}>
               <DataGrid
                  rows={tableBody}
                  columns={columns}
                  pageSize={5}
                  rowsPerPageOptions={[5]}
                  disableSelectionOnClick
                  sx={{
                     boxShadow: 0,
                     border: 0,
                   }}
               />
            </div>
         </Paper>
      </Card>
   );
}

async function getKeys(setKeys, environment) {
   let response = await axios.get(`masters/apiKeys/${environment}`);
   setKeys(response.data.map(d => {
      d.createdAt = new Date(d.createdAt).toLocaleDateString();
      return d;
   }));
}

function ApiKeys() {
   const navigate = useNavigate();
   const { t } = useTranslation();
   const { environment } = useContext(EnvironmentContext);
   const [keys, setKeys] = useState([]);
   const [loading, setLoading] = useState(true);
   const [openDialog, setOpenDialog] = useState(false);
   const [apiKeyId, setApiKeyId] = useState("");
   
   useEffect(() => {
      (async () => {
         try {
            await getKeys(setKeys, environment);
         }
         catch {
            console.error("Content no loaded");
         }

         setLoading(false);
      })()
   }, [environment, loading])

   const columns = [
      { field: 'enabled', headerName: 'Status', width: 150, renderCell: (params) => params.row.enabled ? <Chip label={"Active"} /> : <Chip label={"Inactive"} /> },
      { field: 'id', headerName: 'Key', width: 260 },
      { field: 'name', headerName: 'Comments', width: 170 },
      { field: 'createdAt', headerName: 'Created In', width: 170 },
      { field: 'action', headerName: 'Action', width: 190, renderCell: (params) => ( 
         <Stack spacing={2} direction="row">
            <Button variant="text" startIcon={<Edit2 size={18} />} onClick={() => navigate("/settings/api-keys-edit/" + params.row.id)}>{t("Edit")}</Button>
            <Button variant="text" startIcon={<Trash2 size={18} />} onClick={() => handleClickOpenDialog(params.row.id)}>{t("Deletet")}</Button>
         </Stack>
      )},
   ];

   const deleteApikey = async () => {
      setLoading(true);
      await axios.delete(`masters/apiKeys/${apiKeyId}`);
      setKeys(prev => prev.filter(key => key.id !== apiKeyId));
      setOpenDialog(false);
   }

   const handleClickOpenDialog = (id) => {
      setApiKeyId(id);
      setOpenDialog(true);
   };

   const handleCloseDialog = () => {
      setOpenDialog(false);
   };

   return (
      <React.Fragment>
         <Helmet title="API Keys" />
         <Typography variant="h3" gutterBottom display="inline">
            API Keys
         </Typography>

         <Breadcrumbs aria-label="Breadcrumb" mt={2}>
            <Typography>{t("Configurations")}</Typography>
         </Breadcrumbs>

         <Divider my={6} />

         <Grid container spacing={6}>
            <Grid item xs={12} lg={4} xl={3}>
               <ParametersCard />
               <EnlacesCard />
            </Grid>
            <Grid item xs={12} lg={8} xl={9}>
               {loading ? <Loader /> :
                  <>
                     <KeysCard columns={columns} tableBody={keys} />
                     <Button variant="contained" onClick={() => navigate("/settings/api-keys-create", { replace: true })}>{t("New")} API Key</Button>
                  </>
               }
            </Grid>
         </Grid>
         <Dialog
            open={openDialog}
            onClose={handleCloseDialog}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
         >
            <DialogTitle id="alert-dialog-title">
               {t("Alert")}
            </DialogTitle>
            <DialogContent>
               <DialogContentText id="alert-dialog-description">
                  {t("Are you sure you want to delete this resource?")}
               </DialogContentText>
            </DialogContent>
            <DialogActions>
               <Button onClick={handleCloseDialog}>{t("Cancel")}</Button>
               <Button onClick={deleteApikey} autoFocus>
                  {t("Agree")}
               </Button>
            </DialogActions>
         </Dialog>
      </React.Fragment>
   );
}

export default ApiKeys;
