import React, { useEffect } from 'react'
import useAuth from "../../hooks/useAuth";
import styles from "../../styles/SignOut.module.css";
import { Avatar } from "@mui/material";

function SignOut() {
    const { signOut } = useAuth();

    useEffect(() => {
        const execSignOut = async () => {
            await signOut();
        }

        execSignOut();
    }, [signOut])

    return (
        <>
            <Avatar alt="iPlus" src="/static/img/services/iplus.png" sx={{ width: 100, height: 100 }} className={styles['img-cover']} />
            <h2>Redireccionando...</h2>
        </>
    )
}

export default SignOut;