import React from 'react'
import { UserManager } from 'oidc-client';

function SignInCallBack() {
    console.log("signIn")
    new UserManager({response_mode:"query"}).signinRedirectCallback()
        .then(function() {
            console.log("signInRedirect")
            window.location = "/";
        })
        .catch(function(e) {
            console.log("Error")
            console.error(e);
        });
        return (
            <></>
        )
}

export default SignInCallBack;