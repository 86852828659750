import React, { useEffect, useState } from "react";
import styled from "@emotion/styled";
import { Helmet } from "react-helmet-async";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { UserManager } from 'oidc-client';
import { oidcConfig } from "../../config";

import {
    Avatar as MuiAvatar,
    Button,
    CardHeader,
    CardContent,
    Grid,
    TextField,
    MenuItem,
    Stack,
    Breadcrumbs as MuiBreadcrumbs,
    Card as MuiCard,
    Divider as MuiDivider,
    Typography,
} from "@mui/material";
import { spacing } from "@mui/system";

const userManager = new UserManager(oidcConfig);
const Card = styled(MuiCard)(spacing);
const Divider = styled(MuiDivider)(spacing);
const Breadcrumbs = styled(MuiBreadcrumbs)(spacing);

const Spacer = styled.div(spacing);
const Avatar = styled(MuiAvatar)`
  display: inline-block;
  height: 128px;
  width: 128px;
`;

const Centered = styled.div`
  text-align: center;
`;

const SpaceBetween = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 100px;
`;

function AccountCard() {
    const { t } = useTranslation();

    return(
        <Card mb={6}>
            <CardContent>
                <Spacer mb={4} />

                <Centered>
                  <Avatar alt="No display" src="/static/img/illustrations/waiting.png" />
                  <Typography variant="body2" component="div" gutterBottom>
                    <Button variant="contained" size="small" component="label">{t("UploadPhoto")}<input type="file" hidden /></Button>
                  </Typography>
                </Centered>
            </CardContent>
      </Card>
    );
}

function CompanyDataCard({ profile, setProfile }) {
    const { t } = useTranslation();
  
    return (
      <Card mb={6}>
        <CardHeader title={t("CompanyData")} />
        <CardContent>
          <TextField
              margin="dense"
              id="company_name"
              label={t("CompanyName")}
              name="companyName"
              variant="standard" 
              fullWidth
              value={profile.companyName}
              onChange={(e) => handleChange(setProfile, e)}
            />
            <TextField
              margin="dense"
              id="company_url"
              label={t("WebSite")}
              name="webSite"
              variant="standard" 
              fullWidth
              value={profile.webSite}
              onChange={(e) => handleChange(setProfile, e)}
            />
            <TextField
              margin="dense"
              id="company_identification"
              label={t("TaxId")}
              name="taxId"
              variant="standard" 
              fullWidth
              value={profile.taxId}
              onChange={(e) => handleChange(setProfile, e)}
            />
            <TextField 
              id="company_voucher" 
              label={t("TaxReceipt")} 
              name="taxReceipt"
              select
              margin="dense"
              variant="standard" 
              fullWidth
              value={profile.taxReceipt}
              onChange={(e) => handleChange(setProfile, e)}
              >
                <MenuItem value="01 Crédito Fiscal">01 Crédito Fiscal</MenuItem>
            </TextField>
        </CardContent>
      </Card>
    );
}

function DeleteAccountCard() {
  const { t } = useTranslation();

  return(
      <Card mb={6}>
          <CardContent>
              <Spacer mb={4} />

              <Centered>
              <Typography variant="body2" component="div" gutterBottom>
                  {t("Cancel your subscription and delete all data from your account in this service")}
                </Typography>
                <Spacer mb={5} />
                <Typography variant="body2" component="div" gutterBottom>
                  <Button variant="outlined" size="small" color="error">{t("Delete")} {t("Account")}</Button>
                </Typography>
              </Centered>
          </CardContent>
    </Card>
  );
}

function AccountDetailsCard({ profile, setProfile }) {
  const { t } = useTranslation();
  
  return (
    <Card mb={6}>
      <CardHeader title={t("AccountDetails")} />
      <CardContent>
        <TextField
          autoFocus
          margin="dense"
          id="account_email"
          label={t("Email")}
          name="email"
          variant="standard" 
          fullWidth
          value={profile.email}
          onChange={(e) => handleChange(setProfile, e)}
        />
        <SpaceBetween>
          <TextField            
            margin="dense"
            id="account_movil_phone"
            label={t("MobilePhone")}
            name="mobilePhone"
            variant="standard" 
            fullWidth
            value={profile.mobilePhone}
            onChange={(e) => handleChange(setProfile, e)}
          />
          <TextField
            margin="dense"
            id="account_password"
            label={t("Password")}
            name="password"
            variant="standard" 
            fullWidth
            type="password"
            value={profile.password}
            onChange={(e) => handleChange(setProfile, e)}
          />
        </SpaceBetween>
      </CardContent>
    </Card>
  );
}

function BillingDetailsCard({ profile, setProfile }) {
  const { t } = useTranslation();
  
  return (
    <Card mb={6}>
      <CardHeader title={t("BillingDetails")} />
      <CardContent>
        <SpaceBetween>
          <TextField
            margin="dense"
            id="billing_name"
            label={t("Name")}
            name="family_name"
            variant="standard" 
            fullWidth
            value={profile.family_name}
            onChange={(e) => handleChange(setProfile, e)}
          />
          <TextField
            margin="dense"
            id="billing_last_name"
            label={t("LastName")}
            name="given_name"
            variant="standard" 
            fullWidth
            value={profile.given_name}
            onChange={(e) => handleChange(setProfile, e)}
          />
        </SpaceBetween>
          <TextField
            margin="dense"
            id="billing_address"
            label={t("Address")}
            name="address"
            variant="standard" 
            fullWidth
            value={profile.address}
            onChange={(e) => handleChange(setProfile, e)}
          />
        <SpaceBetween>
          <TextField 
            id="billing_country" 
            label={t("Country")} 
            name="country"
            select
            margin="dense"
            variant="standard" 
            fullWidth
            value={profile.country}
            onChange={(e) => handleChange(setProfile, e)}
            >
              <MenuItem value=""></MenuItem>
          </TextField>
          <TextField 
            id="billing_province" 
            label={t("Province")} 
            name="province"
            select
            margin="dense"
            variant="standard" 
            fullWidth
            value={profile.province}
            onChange={(e) => handleChange(setProfile, e)}
            >
              <MenuItem value=""></MenuItem>
          </TextField>
        </SpaceBetween>
        <SpaceBetween>
          <TextField 
            id="billing_city" 
            label={t("City")} 
            name="city"
            select
            margin="dense"
            variant="standard" 
            fullWidth
            value={profile.city}
            onChange={(e) => handleChange(setProfile, e)}
            >
              <MenuItem value=""></MenuItem>
          </TextField>
          <TextField
            margin="dense"
            id="billing_postal_code"
            label={t("PostalCode")}
            name="postalCode"
            variant="standard" 
            fullWidth
            value={profile.postalCode}
            onChange={(e) => handleChange(setProfile, e)}
          />
        </SpaceBetween>
      </CardContent>
    </Card>
  );
}

function handleChange(callBack, event) {
  const name = event.target.name;
  const value = event.target.value;
  callBack(values => ({...values, [name]: value}))
}

function Profile() {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [profile, setProfile] = useState({ 
    companyName: "iPlus Technology",
    webSite: "www.iplustechnology.com",
    taxId: "Rnc 131-000000-1",
    taxReceipt: "01 Crédito Fiscal",
    sub: "",
    email: "",
    mobilePhone: "",
    password: "",
    family_name: "",
    given_name: "",
    address: "",
    country: "",
    province: "",
    city: "",
    postalCode: "",
  });
  
  useEffect(() => {
    const initialize = async () => {
      try {
        const user = await userManager.getUser();
        setProfile(prevProfile => { return {...prevProfile, ...user.profile}});
      }
      catch (err) {
        console.error(err);
      }
    }

    initialize();
  }, [])
  
  return (
    <React.Fragment>
        <Helmet title={t("Profile")} />
        <Typography variant="h3" gutterBottom display="inline">
            {t("Profile")}
        </Typography>

        <Breadcrumbs aria-label="Breadcrumb" mt={2}>
            <Typography>{t("MyAccount")}</Typography>
        </Breadcrumbs>

        <Divider my={6} />

        <Grid container spacing={6}>
            <Grid item xs={12} lg={8} xl={9}>
                <AccountDetailsCard profile={profile} setProfile={setProfile} />
                <BillingDetailsCard profile={profile} setProfile={setProfile} />
                <Stack spacing={5} direction="row">
                  <Button variant="outlined" onClick={() => navigate("/dashboard/emails-relay", { replace: true })}>{t("Cancel")}</Button>
                  <Button variant="contained">{t("SaveChangesBtn")}</Button>
                </Stack>  
            </Grid>
            <Grid item xs={12} lg={4} xl={3}>
                <AccountCard />
                <CompanyDataCard profile={profile} setProfile={setProfile} />
                <DeleteAccountCard />
            </Grid>
        </Grid>
    </React.Fragment>
  )
}

export default Profile;