import React from "react";
import SignoutComponent from "../../components/auth/SignOut";

function SignOut() {
  return (
    <React.Fragment>
        <SignoutComponent />
    </React.Fragment>
  );
}

export default SignOut;
