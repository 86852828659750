import * as React from "react";
import styled from "@emotion/styled";
import { Power } from "react-feather";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

import {
  Tooltip,
  Menu,
  MenuItem,
  IconButton as MuiIconButton,
  ListItemText,
} from "@mui/material";

const IconButton = styled(MuiIconButton)`
  svg {
    width: 22px;
    height: 22px;
  }
`;

const MenuItemSignOut = styled(MenuItem)`
  background-color: ${(props) =>
    props.theme.palette[props.color ? props.color : "primary"].light};
  color: ${(props) => props.theme.palette.common.white};
  &:hover {
    background-color: ${(props) =>
      props.theme.palette[props.color ? props.color : "primary"].light};
  }
`

function NavbarUserDropdown() {
  const [anchorMenu, setAnchorMenu] = React.useState(null);
  const navigate = useNavigate();
  const { t } = useTranslation();

  const toggleMenu = (event) => {
    setAnchorMenu(event.currentTarget);
  };

  const closeMenu = () => {
    setAnchorMenu(null);
  };

  const handleSignOut = async () => {
    navigate("/auth/sign-out");
  };

  return (
    <React.Fragment>
      <Tooltip title="Account">
        <IconButton
          aria-owns={Boolean(anchorMenu) ? "menu-appbar" : undefined}
          aria-haspopup="true"
          onClick={toggleMenu}
          color="inherit"
          size="large"
        >
          <Power />
        </IconButton>
      </Tooltip>
      <Menu
        id="menu-appbar"
        anchorEl={anchorMenu}
        open={Boolean(anchorMenu)}
        onClose={closeMenu}
      >
        <MenuItem onClick={() => console.log("clicked")}>{t("MyAccount")}</MenuItem>
        <MenuItem onClick={() => navigate("/account/subscription", { replace: true })}>{t("Subscription")}</MenuItem>
        <MenuItem onClick={() => navigate("/account/payment-methods", { replace: true })}>{t("PaymentMethods")}</MenuItem>
        <MenuItem onClick={() => navigate("/account/billing", { replace: true })}>{t("Billing")}</MenuItem>
        <MenuItemSignOut onClick={handleSignOut}>
          <ListItemText style={{ textAlign: "center" }}>{t("SignOut")}</ListItemText>
        </MenuItemSignOut>
      </Menu>
    </React.Fragment>
  );
}

export default NavbarUserDropdown;
