import React from "react";
import SignInComponent from "../../components/auth/SignIn";

function SignIn() {
  return (
    <React.Fragment>
        <SignInComponent />
    </React.Fragment>
  );
}

export default SignIn;
