import { createContext, useEffect, useReducer } from "react";
import { UserManager } from 'oidc-client';
import { oidcConfig } from "../config";

const userManager = new UserManager(oidcConfig);
const INITIALIZE = "INITIALIZE";
const SIGN_IN = "SIGN_IN";
const SIGN_OUT = "SIGN_OUT";
const initialState = {
   isAuthenticated: false,
   isInitialized: false,
   user: null,
};

const reducer = (state, action) => {
   if (action.type === INITIALIZE) {
      const { isAuthenticated, user } = action.payload;
      return {
         ...state,
         isAuthenticated,
         isInitialized: true,
         user,
      };
   }
   if (action.type === SIGN_IN) {
      const { user } = action.payload;
      return { ...state, isAuthenticated: true, user };
   }
   if (action.type === SIGN_OUT) {
      return {
         ...state,
         isAuthenticated: false,
         user: null,
      };
   }
   return state;
};

const AuthContext = createContext(null);

function AuthProvider({ children }) {
   const [state, dispatch] = useReducer(reducer, initialState);

   useEffect(() => {
      const initialize = async () => {
         try {
            const user = await userManager.getUser();
            const isAuthenticated = user ? true : false;
            
            if(user.expired) {
               user.expired();
            }

            if (isAuthenticated) {
               dispatch({
                  type: INITIALIZE,
                  payload: { isAuthenticated, user: user || null },
               });
            } else {
               dispatch({
                  type: INITIALIZE,
                  payload: { isAuthenticated, user: null },
               });
            }
         } catch (err) {
            console.error(err);
            dispatch({
               type: INITIALIZE,
               payload: { isAuthenticated: false, user: null },
            });
         }
      };

      initialize();
   }, []);

   const signIn = async () => {
      await userManager.signinRedirect();
   };

   const signOut = () => {
      userManager.signoutRedirect();
   };

   const resetPassword = (email) => { };

   return (
      <AuthContext.Provider
         value={{
            ...state,
            method: "auth0",
            user: {
               id: state?.user?.sub,
               avatar: state?.user?.picture,
               email: state?.user?.email,
               displayName: state?.user?.name,
               role: "user",
            },
            signIn,
            signOut,
            resetPassword,
         }}
      >
         {children}
      </AuthContext.Provider>
   );
}

export { AuthContext, AuthProvider };
