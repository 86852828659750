import axios from "axios";

const axiosInstance = axios.create({
  baseURL: process.env.REACT_APP_PROXY_HOST
});

axiosInstance.interceptors.request.use(function (config) {
  const token = sessionStorage.getItem(`oidc.user:https://login.iplus.app:${process.env.REACT_APP_AUTH_CLIENT_ID}`);
  config.headers.Authorization = token ? `Bearer ${JSON.parse(token).access_token}` : '';

  return config;
});

axiosInstance.interceptors.response.use(
  (response) => response,
  (error) => 
    Promise.reject(
      (error.response && error.response.data) || "Something went wrong"
    )
);

export default axiosInstance;
