import React from "react";
import styled from "@emotion/styled";
import { withTheme } from "@emotion/react";
import { useTranslation } from "react-i18next";
import Chart from "react-chartjs-2";
import { MoreVertical } from "react-feather";

import { 
  CardHeader,
  CardContent, 
  Card as MuiCard, 
  IconButton
} from "@mui/material";
import { spacing } from "@mui/system";

const Card = styled(MuiCard)(spacing);
const ChartWrapper = styled.div`
  height: 240px;
  width: 100%;
`;

const BarChart = ({ title, theme, dataByYear, dataByLastYear }) => {
  const { t } = useTranslation();
  const data = {
    labels: [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec",
    ],
    datasets: [
      {
        label: "Last year",
        backgroundColor: theme.palette.secondary.main,
        borderColor: theme.palette.secondary.main,
        hoverBackgroundColor: theme.palette.secondary.main,
        hoverBorderColor: theme.palette.secondary.main,
        data: dataByYear !== "" && dataByYear !== null && dataByYear !== undefined ? new Array(12).fill(0, 0, 12).map((n, i) => dataByLastYear[`m${(i + 1).toString().padStart(2, '0')}`]) : [],
        barPercentage: 0.75,
        categoryPercentage: 0.5,
      },
      {
        label: "This year",
        backgroundColor: theme.palette.grey[300],
        borderColor: theme.palette.grey[300],
        hoverBackgroundColor: theme.palette.grey[300],
        hoverBorderColor: theme.palette.grey[300],
        data: dataByYear !== "" && dataByYear !== null && dataByYear !== undefined  ? new Array(12).fill(0, 0, 12).map((n, i) => dataByYear[`m${(i + 1).toString().padStart(2, '0')}`]) : [],
        barPercentage: 0.75,
        categoryPercentage: 0.5,
      },
    ],
  };

  const options = {
    maintainAspectRatio: false,
    plugins: {
      legend: {
        display: false,
      },
    },
    scales: {
      y: {
        grid: {
          display: false,
        },
        stacked: false,
        ticks: {
          stepSize: 20,
        },
      },
      x: {
        stacked: false,
        grid: {
          color: "transparent",
        },
      },
    },
  };

  return (
    <Card mb={6}>
      <CardHeader
        action={
          <IconButton aria-label="settings" size="large">
            <MoreVertical />
          </IconButton>
        }
        title={title === "SentThisYear" ? t(title) + " " + new Date().getFullYear() : t(title)}
      />
      <CardContent>
        <ChartWrapper>
          <Chart type="bar" data={data} options={options} />
        </ChartWrapper>
      </CardContent>
    </Card>
  );
};

export default withTheme(BarChart);
