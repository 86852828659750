import React from "react";
import styled from "@emotion/styled";
import { MoreVertical, Trash2 } from "react-feather";
import {
  Card as MuiCard,
  CardHeader,
  IconButton,
  Chip as MuiChip,
  Paper as MuiPaper,
  Table,
  TableBody,
  TableCell as MuiTableCell,
  TableHead,
  TableRow,
  Button
} from "@mui/material";
import { spacing } from "@mui/system";

const Card = styled(MuiCard)(spacing);

const Chip = styled(MuiChip)`
  height: 20px;
  padding: 4px 0;
  font-size: 90%;
  background-color: ${(props) =>
    props.theme.palette[props.color ? props.color : "primary"].light};
  color: ${(props) => props.theme.palette.common.white};
`;

const Paper = styled(MuiPaper)(spacing);

const TableWrapper = styled.div`
  overflow-y: auto;
  max-width: calc(100vw - ${(props) => props.theme.spacing(12)});
`;

const TableCell = styled(MuiTableCell)`
    text-transform: capitalize;
`;

function AddActions(id, actions, handler) {
  actions = (actions.split(":")[1]).split("|");

  return <TableCell key={id}>{actions.map((action, index) => <Button variant="text" key={index} startIcon={<Trash2 size={18} />} onClick={() => handler(id)}>{action}</Button>)}</TableCell>
}

function TableBodyRow(record, bodyKeys, handlers) {
  const rows = [];
  let reg = 0;

  for(let key of bodyKeys) {
    if(key === "status_name" )
      rows.push(<TableCell key={reg++}><Chip label={record[key]} /></TableCell>)
    else if(key === "enabled" )
      rows.push(<TableCell key={reg++}>{record[key] ? <Chip label={"Active"} /> : <Chip label={"Inactive"} />}</TableCell>)
    else if(key.startsWith("actions:"))
      rows.push(AddActions(record.id, key, handlers[key.split(":")[1]]));
    else
      rows.push(<TableCell key={reg++}>{record[key]}</TableCell>)
  }

  return rows;
} 

function TablePage({ title, tableHeader, tableBody, bodyKeys, handlers }) {
  
  return (
    <Card mb={6}>
      <CardHeader
        action={
          <IconButton aria-label="settings" size="large">
            <MoreVertical />
          </IconButton>
        }
        title={title}
      />
      <Paper>
        <TableWrapper>
          <Table>
            <TableHead>
              <TableRow>
                  {tableHeader.length > 0 && tableHeader.map((head, index) => <TableCell key={index}>{head}</TableCell>)}
              </TableRow>
            </TableHead>
            <TableBody>
              {tableBody.length > 0 && typeof(tableBody) === "object" && tableBody.map((row) => (
                <TableRow key={row.id}>
                  {TableBodyRow(row, bodyKeys, handlers)}
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableWrapper>
      </Paper>
    </Card>
  );
}

export default TablePage;
