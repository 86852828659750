import React, { useState, useRef, useContext } from "react";
import styled from "@emotion/styled";
import { Helmet } from "react-helmet-async";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { EnvironmentContext } from "../../contexts/EnvironmentContext";
import uuid from 'react-uuid';
import axios from "../../utils/axios";
import LoadingButton from '@mui/lab/LoadingButton';

import {
  CardContent,
  Grid,
  Breadcrumbs as MuiBreadcrumbs,
  Card as MuiCard,
  Divider as MuiDivider,
  Typography,
  Button,
  Stack,
  TextField
} from "@mui/material";
import { spacing } from "@mui/system";

const Card = styled(MuiCard)(spacing);
const Divider = styled(MuiDivider)(spacing);
const Breadcrumbs = styled(MuiBreadcrumbs)(spacing);
const ParametersTable = styled.table`
  width: 100%;
  font-size: 12px;
  
  td:last-child {
    padding: 2px 0;
    color: ${(props) =>
      props.theme.palette[props.color ? props.color : "primary"].light};
  }
`

function DetailApiKeyCard({ apikey, setApikey, tb_name_ref, saving }) {
    const { t } = useTranslation();
    
    const handleChange = (event) => {
        const name = event.target.name;
        const value = event.target.value;
        setApikey(values => ({...values, [name]: value}))
    }

    return (
        <Card mb={6}>
        <CardContent>
            <Typography variant="h6" gutterBottom>
                {t("ApiKeyDetailTitle")}
            </Typography>
            <ParametersTable>
            <tbody>
                <tr>
                    <td>
                        <TextField 
                            id="tb_name" 
                            label={t("Name")} 
                            variant={saving ? "filled" : "standard"} 
                            fullWidth 
                            margin="dense" 
                            name="name" 
                            value={apikey.name} 
                            onChange={handleChange} 
                            inputRef={tb_name_ref} 
                            InputProps={{readOnly: saving}} 
                            autoFocus 
                        />
                    </td>
                </tr>
                <tr>
                    <td>
                        <TextField 
                            id="tb_apikey" 
                            label="API Key" 
                            variant="filled" 
                            fullWidth 
                            margin="dense" 
                            name="id" 
                            value={apikey.id} 
                            onChange={handleChange} 
                            InputProps={{readOnly: true}} 
                        />
                    </td>
                </tr>
            </tbody>
            </ParametersTable>
        </CardContent>
        </Card>
    );
}

function NewApiKey() {
    const navigate = useNavigate();
    const { t } = useTranslation();
    const { environment } = useContext(EnvironmentContext);
    const [apikey, setApikey] = useState({ id: uuid(), name: "", environmentId: environment });
    const [saving, setSaving] = useState(false);
    const tb_name = useRef();
    
    const sendNewApiKey = async () => {
        if(apikey.name === "") {
            tb_name.current.focus();
            return;
        }

        setSaving(true);
        await axios.post("masters/apiKeys", apikey);
        navigate("/settings/api-keys", { replace: true });
    }

    return (
        <React.Fragment>
        <Helmet title="API Keys" />
        <Typography variant="h3" gutterBottom display="inline">
            API Key
        </Typography>

        <Breadcrumbs aria-label="Breadcrumb" mt={2}>
            <Typography>{t("Configurations")}</Typography>
        </Breadcrumbs>

        <Divider my={6} />

        <Grid container spacing={6}>
            <Grid item xs={12} lg={7} xl={7}>
            <DetailApiKeyCard apikey={apikey} setApikey={setApikey} tb_name_ref={tb_name} saving={saving} />
            <Stack spacing={5} direction="row">
                <Button variant="outlined" onClick={() => navigate("/settings/api-keys", { replace: true })}>{t("Cancel")}</Button>
                <LoadingButton
                    onClick={sendNewApiKey}
                    loading={saving}
                    variant="contained"
                    >
                    {t("SaveChangesBtn")}
                </LoadingButton>
            </Stack>
            </Grid>
        </Grid>
        </React.Fragment>
    );
}

export default NewApiKey;
