import React, { useState } from "react";
import styled from "@emotion/styled";
import Badge from "../../components/Badge";
import { Helmet } from "react-helmet-async";
import { useTranslation } from "react-i18next";

import {
  CardContent,
  CardMedia,
  Grid,
  Breadcrumbs as MuiBreadcrumbs,
  Card as MuiCard,
  Divider as MuiDivider,
  Typography,
  FormControl as MuiFormControl,
  Select,
  InputLabel,
  MenuItem,
  Button,
  TextField,
  Input,
} from "@mui/material";
import { spacing } from "@mui/system";

const Card = styled(MuiCard)(spacing);
const Divider = styled(MuiDivider)(spacing);
const Breadcrumbs = styled(MuiBreadcrumbs)(spacing);

const FormControl = styled(MuiFormControl)`
  div {
    font-size: .7rem;
    margin-top: 0 !important;
  }
`

function CreditCard({ title, cardNumber, expire, value, imageUrl }) {
  const { t } = useTranslation();
  
  return (
    <Card mb={6}>
      <CardContent>
        <Grid container spacing={3}>
          <Grid item xs={2.2} pr={0}>
            <CardMedia
              component="img"
              height="30"
              image={imageUrl}
              alt="Image"
            />
          </Grid>
          <Grid item xs={9.8} pb={8}>
            <Typography variant="h6" gutterBottom>
              {title}
            </Typography>
            <Typography variant="h5" gutterBottom>
              {cardNumber}
            </Typography>
            <Typography gutterBottom>
              {t("Expire")} {expire}
            </Typography>
          </Grid>
        </Grid>
        <Divider my={6} />
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <div style={{"display": "flex", "alignItems": "center", "marginTop": "-10px"}}>
                <FormControl variant="standard" sx={{ m: 1, minWidth: 50 }} size="small" style={{ marginRight: "auto" }}>
                    <InputLabel id="type-select-label"></InputLabel>
                    <Select
                        labelId="type-select-label"
                        id="type-select"
                        value={value}
                        >
                        <MenuItem value="Principal">Principal</MenuItem>
                        <MenuItem value="Backup1">Backup1</MenuItem>
                        <MenuItem value="Backup2">Backup2</MenuItem>
                    </Select>
                </FormControl>
                <Badge label={t("Delete")} color="secondary" cursor="pointer" />
                <Badge label={t("Edit")} cursor="pointer" />
            </div>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
}

function NewCard({ callBack }) {
  const { t } = useTranslation();

  return (
    <Card mb={6}>
      <CardContent>
        <Grid container spacing={3}>
          <Grid item xs={2.2} pr={0}>
          </Grid>
          <Grid item xs={9.8} pb={8}>
            <TextField
              autoFocus
              margin="dense"
              id="card_name"
              label={t("CardName")}
              variant="standard" 
              size="small"
              fullWidth
            />
            <TextField
              margin="dense"
              id="card_number"
              label={t("CardNumber")}
              variant="standard" 
              size="small"
              fullWidth
            />
            <MuiFormControl variant="standard" sx={{ m: 1, width: 30 }} size="small">
              <InputLabel htmlFor="card_mm-simple">MM</InputLabel>
              <Input id="card_mm" />
            </MuiFormControl>
            <MuiFormControl variant="standard" sx={{ m: 1, width: 5 }} size="small">
              <InputLabel htmlFor="card_mm-simple">/</InputLabel>
            </MuiFormControl>
            <MuiFormControl variant="standard" sx={{ m: 1, width: 30 }} size="small">
              <InputLabel htmlFor="card_yy-simple">YY</InputLabel>
              <Input id="card_yy" />
            </MuiFormControl>
          </Grid>
        </Grid>
        <Divider my={6} />
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <div style={{"display": "flex", "alignItems": "center", "marginTop": "-10px"}}>
                <FormControl variant="standard" sx={{ m: 1, minWidth: 50 }} size="small" style={{ marginRight: "auto" }}>
                    <InputLabel id="type-select-label"></InputLabel>
                    <Select
                        labelId="type-select-label"
                        id="type-select-new"
                        value="Principal"
                        >
                        <MenuItem value="Principal">Principal</MenuItem>
                        <MenuItem value="Backup1">Backup1</MenuItem>
                        <MenuItem value="Backup2">Backup2</MenuItem>
                    </Select>
                </FormControl>
                <Badge label={t("Cancel")} color="secondary" cursor="pointer" onClick={() => callBack(false) } />
                <Badge label={t("Save")} cursor="pointer" />
            </div>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
}

function PaymentMethods() {
  const { t } = useTranslation();
  const [newCard, setNewCard] = useState(false);

  return (
    <React.Fragment>
      <Helmet title={t("PaymentMethods")} />
      <Typography variant="h3" gutterBottom display="inline">
        {t("PaymentMethods")}
      </Typography>

      <Breadcrumbs aria-label="Breadcrumb" mt={2}>
        <Typography>{t("MyAccount")}</Typography>
      </Breadcrumbs>

      <Divider my={6} />
      <Typography variant="h4" gutterBottom display="block">
        {t("CreditCards")}
      </Typography>

      <Grid container spacing={6} mt={2}>
        {newCard && 
          <Grid item xs={8} lg={6.1} xl={3}>
            <NewCard callBack={setNewCard} />
          </Grid>
        }
        <Grid item xs={8} lg={6.1} xl={3}>
          <CreditCard title="Juan Pérez Gutierrez" cardNumber="**** **** **** 2514" expire="07/24" value="Principal" imageUrl="/static/img/services/amazon.png" />
        </Grid>
        <Grid item xs={8} lg={6.1} xl={3}>
          <CreditCard title="Juan Pérez Gutierrez" cardNumber="**** **** **** 3011" expire="07/24" value="Backup1" imageUrl="/static/img/services/mandrill.png" />
        </Grid>
        <Grid item xs={8} lg={6.1} xl={3}>
          <CreditCard title="Juan Pérez Gutierrez" cardNumber="**** **** **** 4009" expire="07/24" value="Backup2" imageUrl="/static/img/services/sendgrid.png" />
        </Grid>
        <Grid item xs={8} lg={6.1} xl={12} mt={-5}>
            {!newCard && <Button variant="contained" onClick={() => setNewCard(true)}>{t("New")} {t("Card")}</Button>}
        </Grid>
      </Grid>
    </React.Fragment>
  );
}

export default PaymentMethods;
