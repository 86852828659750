import React from "react";
import { Navigate } from 'react-router-dom';

import async from "./components/Async";

// Layouts
import DashboardLayout from "./layouts/Dashboard";
import AuthLayout from "./layouts/Auth";

// Guards
import AuthGuard from "./components/guards/AuthGuard";

// Auth components
import SignIn from "./pages/auth/SignIn"
import SignInCallBack from "./pages/auth/SignInCallBack"
import SignOut from "./pages/auth/SignOut"
import SignOutCallBack from "./pages/auth/SignOutCallBack"
import Page404 from "./pages/auth/Page404";
import Page500 from "./pages/auth/Page500";

// Transacciones components
import EmailsEnviados from "./pages/transacciones/EmailsEnviados";
import SmsEnviados from "./pages/transacciones/SmsEnviados";
import PushNotificationsEnviados from "./pages/transacciones/PushNotificationsEnviados";

// Configuraciones components
import ApiKeys from "./pages/configuraciones/ApiKeys";
import NewApiKey from "./pages/configuraciones/NewApiKey";
import EditApiKey from "./pages/configuraciones/EditApiKey";
import ServiceProviders from "./pages/configuraciones/ServiceProviders";

// Documentaciones components
import EmailsRelayDoc from "./pages/docs/EmailsRelay";
import SmsRelayDoc from "./pages/docs/SmsRelay";
import PushNotificationsRelayDoc from "./pages/docs/PushNotificationsRelay";

// Account components
import PaymentMethods from './pages/account/PaymentMethods';
import Subscription from './pages/account/Subscription';
import Billing from './pages/account/Billing';
import Profile from './pages/account/Profile';

// Dashboard components
const EmailsRelay = async(() => import("./pages/dashboard/EmailsRelay"));
const SmsRelay = async(() => import("./pages/dashboard/SmsRelay"));
const PushNotificationsRelay = async(() => import("./pages/dashboard/PushNotificationsRelay"));

const routes = [
    {
      path: "/",
      element: <Navigate replace to="/dashboard/emails-relay" />,
    },
    {
      path: "dashboard",
      element: (
        <AuthGuard>
          <DashboardLayout />
        </AuthGuard>
      ),
      children: [
        {
          path: "emails-relay",
          element: <EmailsRelay />,
        },
        {
          path: "sms-relay",
          element: <SmsRelay />,
        },
        {
          path: "push-notifications-relay",
          element: <PushNotificationsRelay />,
        }
      ],
    },
    {
      path: "transactions",
      element: (
        <AuthGuard>
          <DashboardLayout />
        </AuthGuard>
      ),
      children: [
        {
          path: "emails-sent",
          element: <EmailsEnviados />,
        },
        {
          path: "sms-sent",
          element: <SmsEnviados />,
        },
        {
          path: "push-notifications-sent",
          element: <PushNotificationsEnviados />,
        },
      ],
    },
    {
      path: "settings",
      element: (
        <AuthGuard>
          <DashboardLayout />
        </AuthGuard>
      ),
      children: [
        {
          path: "api-keys",
          element: <ApiKeys />,
        },
        {
          path: "api-keys-create",
          element: <NewApiKey />,
        },
        {
          path: "api-keys-edit/:apiKeyId",
          element: <EditApiKey />,
        },
        {
          path: "service-providers",
          element: <ServiceProviders />,
        },
      ]
    },
    {
      path: "documentations",
      element: (
        <AuthGuard>
          <DashboardLayout />
        </AuthGuard>
      ),
      children: [
        {
          path: "emails-relay",
          element: <EmailsRelayDoc />,
        },
        {
          path: "sms-relay",
          element: <SmsRelayDoc />,
        },
        {
          path: "push-notifications-relay",
          element: <PushNotificationsRelayDoc />,
        }
      ]
    },
    {
      path: "account",
      element: (
        <AuthGuard>
          <DashboardLayout />
        </AuthGuard>
      ),
      children: [
        {
          path: "profile",
          element: <Profile />,
        },
        {
          path: "payment-methods",
          element: <PaymentMethods />,
        },
        {
          path: "subscription",
          element: <Subscription />
        },
        {
          path: "billing",
          element: <Billing />
        }
      ]
    },
    {
        path: "auth",
        element: <AuthLayout />,
        children: [
          {
            path: "sign-in",
            element: <SignIn />,
          },
          {
            path: "signin-oidc",
            element: <SignInCallBack />,
          },
          {
            path: "sign-out",
            element: <SignOut />,
          },
          {
            path: "signout-callback-oidc",
            element: <SignOutCallBack />,
          },
          {
            path: "404",
            element: <Page404 />,
          },
          {
            path: "500",
            element: <Page500 />,
          },
        ],
    },
    {
        path: "*",
        element: <AuthLayout />,
        children: [
          {
            path: "*",
            element: <Page404 />,
          },
        ],
    }
]

export default routes;