import React, { useEffect } from 'react'
import useAuth from "../../hooks/useAuth";
import styles from "../../styles/SignIn.module.css";
import { Avatar } from "@mui/material";

function SignIn() {
    const { signIn } = useAuth();

    useEffect(() => {
        const execSignIn = async () => {
            await signIn();
        }

        execSignIn();
    }, [signIn])

    return (
        <>
            <Avatar alt="iPlus" src="/static/img/services/iplus.png" sx={{ width: 100, height: 100 }} className={styles['img-cover']} />
            <h2>Redireccionando...</h2>
        </>
    )
}

export default SignIn;