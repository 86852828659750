import React, { useEffect, useState, useContext } from "react";
import styled from "@emotion/styled";
import Badge from "../../components/Badge";
// import Table from "../../components/Table";
import { NavLink } from "react-router-dom";
import { Helmet } from "react-helmet-async";
import { useTranslation } from "react-i18next";
import { EnvironmentContext } from "../../contexts/EnvironmentContext";
import { DataGrid } from '@mui/x-data-grid';
import axios from "../../utils/axios";
import Loader from "../../components/Loader";

import {
   CardContent,
   Grid,
   Link,
   Breadcrumbs as MuiBreadcrumbs,
   Card as MuiCard,
   Divider as MuiDivider,
   Typography,
   CardHeader,
   Paper,
} from "@mui/material";
import { spacing } from "@mui/system";

const Card = styled(MuiCard)(spacing);
const Divider = styled(MuiDivider)(spacing);
const Breadcrumbs = styled(MuiBreadcrumbs)(spacing);

function ApiKeyCard({ apiKeys, onHandleFilter }) {
   return (
      <Card mb={6}>
         <CardContent>
            <Typography variant="h6" gutterBottom>
               Api Key
            </Typography>
            <div>
               <Badge label="Todas" cursor="pointer" onClick={() => onHandleFilter("")} />
               {apiKeys.map(apiKey => <Badge key={apiKey.id} label={apiKey.name} color="opacityPrimary" cursor="pointer" onClick={() => onHandleFilter(apiKey.id)} />)}
            </div>
         </CardContent>
      </Card>
   );
}

function EnlacesCard() {
   const { t } = useTranslation();

   return (
      <Card mb={6}>
         <CardContent>
            <Typography variant="h6" gutterBottom>
               {t("Links")}
            </Typography>
            <Typography variant="caption" display="block" gutterBottom>
               <Link component={NavLink} to="/settings/api-keys">
                  API Keys
               </Link>
            </Typography>
            <Typography variant="caption" display="block" gutterBottom>
               <Link component={NavLink} to="/account/billing">
                  {t("Billing")}
               </Link>
            </Typography>
            <Typography variant="caption" display="block" gutterBottom>
               <Link component={NavLink} to="/transactions/emails-sent">
                  Emails {t("Sent")}
               </Link>
            </Typography>
            <Typography variant="caption" display="block" gutterBottom>
               <Link component={NavLink} to="/transactions/sms-sent">
                  SMS {t("Sent")}
               </Link>
            </Typography>
         </CardContent>
      </Card>
   );
}

const columns = [
   { field: 'messageStatusId', headerName: 'Status', width: 150 },
   { field: 'date', headerName: 'Date', width: 260 },
   { field: 'sender', headerName: 'Sender', width: 170 },
   { field: 'consignee', headerName: 'Consignee', width: 170 },
   { field: 'subject', headerName: 'Title', width: 190 },
];

function EnviadosCard({ tableBody }) {
   const { t } = useTranslation();
   //const tableHeader = [t("Status"), t("Date"), t("Sender"), t("Consignee"), t("Subject")];
   //const bodyKeys = ["messageStatusId", "date", "sender", "consignee", "subject"];

   return (
      //<Table title={t("Shipments")} tableHeader={tableHeader} tableBody={tableBody} bodyKeys={bodyKeys} />
      <Card mb={6}>
         <CardHeader title={t("Shipments")} />
         <Paper>
            <div style={{ height: 400, width: '100%' }}>
               <DataGrid
                  rows={tableBody}
                  columns={columns}
                  pageSize={5}
                  rowsPerPageOptions={[5]}
                  disableSelectionOnClick
                  sx={{
                     boxShadow: 0,
                     border: 0,
                   }}
               />
            </div>
         </Paper>
      </Card>
   );
}

async function getPushNotificationsSent(setPushNotificacions, setPushNotificationsFilter, environment) {
   let response = await axios.get(`/transactions/messages/${environment}?messageTypeId=pushNotifications&messageStatusId=Delivered`);
   setPushNotificacions(response.data);
   setPushNotificationsFilter(response.data);
}

async function getApiKeys(setapiKeys, environment) {
   let response = await axios.get(`/masters/apikeys/${environment}`);
   setapiKeys(response.data);
}

function PushNotificationEnviados() {
   const { t } = useTranslation();
   const { environment } = useContext(EnvironmentContext);
   const [pushNotifications, setPushNotificacions] = useState([]);
   const [apiKeys, setapiKeys] = useState([]);
   const [pushNotificationsFilter, setPushNotificationsFilter] = useState([]);
   const [loading, setLoading] = useState(true);

   useEffect(() => {
      (async () => {
         try {
            await getPushNotificationsSent(setPushNotificacions, setPushNotificationsFilter, environment);
            await getApiKeys(setapiKeys, environment);
         }
         catch {
            console.error("Content no loaded");
         }

         setLoading(false);
      })()
   }, [environment])

   const filterPushNotifications = (apiKeyId) => {
      if (apiKeyId === "") {
         setPushNotificationsFilter(pushNotifications);
      }
      else {
         setPushNotificationsFilter(pushNotifications.filter(push => push.apiKeyId === apiKeyId));
      }
   }

   return (
      <React.Fragment>
         <Helmet title={`SMS ${t("Sent")}`} />
         <Typography variant="h3" gutterBottom display="inline">
            Push Notificacions {t("Sent")}
         </Typography>

         <Breadcrumbs aria-label="Breadcrumb" mt={2}>
            <Typography>{t("Transactions")}</Typography>
         </Breadcrumbs>

         <Divider my={6} />

         <Grid container spacing={6}>
            <Grid item xs={12} lg={4} xl={3}>
               <ApiKeyCard apiKeys={apiKeys} onHandleFilter={filterPushNotifications} />
               <EnlacesCard />
            </Grid>
            <Grid item xs={12} lg={8} xl={9}>
               {loading ? <Loader /> : <EnviadosCard tableBody={pushNotificationsFilter} />}
            </Grid>
         </Grid>
      </React.Fragment>
   );
}

export default PushNotificationEnviados;
