import React, { useState, useEffect } from "react";
import styled from "@emotion/styled";
import Table from "../../components/Table";
import { Helmet } from "react-helmet-async";
import { NavLink } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { UserManager } from 'oidc-client';
import { oidcConfig } from "../../config";
import axios from "../../utils/axios";

import {
   Avatar as MuiAvatar,
   Box,
   CardContent,
   Grid,
   Link,
   Breadcrumbs as MuiBreadcrumbs,
   Card as MuiCard,
   Divider as MuiDivider,
   Typography,
} from "@mui/material";
import { spacing } from "@mui/system";
import BarChart from "../../components/BarChart";

const userManager = new UserManager(oidcConfig);
const Card = styled(MuiCard)(spacing);
const Divider = styled(MuiDivider)(spacing);
const Breadcrumbs = styled(MuiBreadcrumbs)(spacing);

const Spacer = styled.div(spacing);
const Avatar = styled(MuiAvatar)`
  display: inline-block;
  height: 128px;
  width: 128px;
`;

const Centered = styled.div`
  text-align: center;
`;

function AccountCard({ name, position }) {
   const { t } = useTranslation();

   return (
      <Card mb={6}>
         <CardContent>
            <Typography variant="h6" gutterBottom>
               {t("MyAccount")}
            </Typography>

            <Spacer mb={4} />

            <Centered>
               <Avatar alt={name} src="/static/img/illustrations/waiting.png" />
               <Typography variant="body2" component="div" gutterBottom>
                  <Box fontWeight="fontWeightMedium">{name}</Box>
                  <Box fontWeight="fontWeightRegular">{position}</Box>
               </Typography>
            </Centered>
         </CardContent>
      </Card>
   );
}

function EnlacesCard() {
   const { t } = useTranslation();

   return (
      <Card mb={6}>
         <CardContent>
            <Typography variant="h6" gutterBottom>
               {t("Links")}
            </Typography>
            <Typography variant="caption" display="block" gutterBottom>
               <Link component={NavLink} to="/account/profile">
                  {t("Profile")}
               </Link>
            </Typography>
            <Typography variant="caption" display="block" gutterBottom>
               <Link component={NavLink} to="/account/payment-methods">
                  {t("PaymentMethods")}
               </Link>
            </Typography>
            <Typography variant="caption" display="block" gutterBottom>
               <Link component={NavLink} to="/account/subscription">
                  {t("Subscription")}
               </Link>
            </Typography>
         </CardContent>
      </Card>
   );
}

function BillsCard({ tableBody }) {
   const { t } = useTranslation();
   const tableHeader = [t("Status"), t("Date"), t("BillNo"), t("TaxReceipt"), t("Amount"), t("Action")];
   const bodyKeys = ["enabled", "date", "number", "voucher", "amount", "actions:Download"];

   return (
      <Table title={t("Billings")} tableHeader={tableHeader} tableBody={tableBody} bodyKeys={bodyKeys} />
   );
}

async function getInvoiceSent(dispatch) {
   let response = await axios.get("/bills");
   dispatch(response.data);
}

async function getInvoiceByMonth(dispatch) {
   let date = new Date();
   let response = await axios.get(`/bills/messages/search?year${date.getFullYear()}`);
   dispatch(response.data);
}

function Billing() {
   const { t } = useTranslation();
   const [bills, setBills] = useState([]);
   const [dataByMonth, setDataByMonth] = useState([]);
   const [profile, setProfile] = useState({});

   useEffect(() => {
      (async () => {
         const user = await userManager.getUser();
         setProfile(user.profile);
         await getInvoiceSent(setBills)
         await getInvoiceByMonth(setDataByMonth)
      })()
   }, []);

   return (
      <React.Fragment>
         <Helmet title={t("Billing")} />
         <Typography variant="h3" gutterBottom display="inline">
            {t("Billing")}
         </Typography>

         <Breadcrumbs aria-label="Breadcrumb" mt={2}>
            <Typography>{t("MyAccount")}</Typography>
         </Breadcrumbs>
         <Divider my={6} />
         <Grid container spacing={6}>
            <Grid item xs={12} lg={4} xl={3}>
               <AccountCard name={`${profile.name} ${profile.family_name}`} position={"Lead Developer"} />
               <EnlacesCard />
            </Grid>
            <Grid item xs={12} lg={8} xl={9}>
               <BarChart title="MonthlyBilling" search={dataByMonth} />
               <BillsCard tableBody={bills} />
            </Grid>
         </Grid>
      </React.Fragment>
   )
}

export default Billing;