import React, { createContext } from "react"
import useLocalStorage from "../hooks/useLocalStorage"

export const EnvironmentContext = createContext()

function EnvironmentProvider({children}) {
    const [environment, setEnvironment] = useLocalStorage("environment", "sandbox");
    
    return (
        <EnvironmentContext.Provider value={{environment, setEnvironment}}>
            {children}
        </EnvironmentContext.Provider>
    )
}

export default EnvironmentProvider