import React from "react";
import styled from "@emotion/styled";
import { Helmet } from "react-helmet-async";
import { useTranslation } from "react-i18next";

import {
  Grid,
  Breadcrumbs as MuiBreadcrumbs,
  Divider as MuiDivider,
  Typography,
} from "@mui/material";
import { spacing } from "@mui/system";

const Divider = styled(MuiDivider)(spacing);
const Breadcrumbs = styled(MuiBreadcrumbs)(spacing);


function PushNotificationsRelay() {
  const { t } = useTranslation();

  return (
    <React.Fragment>
      <Helmet title="Push Notifications Relay" />
      <Typography variant="h3" gutterBottom display="inline">
        Push Notifications Relay
      </Typography>

      <Breadcrumbs aria-label="Breadcrumb" mt={2}>
        <Typography>{t("Documentations")}</Typography>
      </Breadcrumbs>

      <Divider my={6} />

      <Grid container spacing={6}>
        <Grid item xs={12}>
          <Typography variant="h4" gutterBottom>{t("Introduction")}</Typography>
          <Typography display="inline">{t("IntroductionBody")} Push Notifications Relay</Typography>
        </Grid>
        <Grid item xs={12}>
          {/* <Typography variant="h4" gutterBottom>{t("TableOfContents")}</Typography>
          <Typography display="inline">{t("TableOfContentsBody")}</Typography> */}
        </Grid>
      </Grid>
    </React.Fragment>
  );
}

export default PushNotificationsRelay;
