import React, { useState, useEffect } from "react";
import styled from "@emotion/styled";
import Badge from "../../components/Badge";
import { Helmet } from "react-helmet-async";
import { useTranslation } from "react-i18next";
import FormDialog from "../../components/FormDialog";
import axios from "../../utils/axios";
import Loader from "../../components/Loader";

import {
   CardContent,
   CardMedia,
   Grid,
   Breadcrumbs as MuiBreadcrumbs,
   Card as MuiCard,
   Divider as MuiDivider,
   Typography,
   FormControl as MuiFormControl,
   Select,
   InputLabel,
   MenuItem,
   Snackbar,
   Alert
} from "@mui/material";
import { spacing } from "@mui/system";

const Card = styled(MuiCard)(spacing);
const Divider = styled(MuiDivider)(spacing);
const Breadcrumbs = styled(MuiBreadcrumbs)(spacing);

const FormControl = styled(MuiFormControl)`
  div {
    font-size: .7rem;
    margin-top: 0 !important;
  }
`

function ServiceCard({ providerId, title, cost, valueOrder, onHandleChangeOrder, imageUrl, accountProviders, initialProviderParameters, servicesOrder }) {
   const { t } = useTranslation();
   const [show, setShow] = useState(false);
   valueOrder = accountProviders.length ? servicesOrder[accountProviders[0].order] : valueOrder;

   return (
      <Card mb={6}>
         <CardContent>
            <Grid container spacing={3}>
               <Grid item xs={2.2} pr={0}>
                  <CardMedia
                     component="img"
                     height="30"
                     image={imageUrl}
                     alt="Image"
                  />
               </Grid>
               <Grid item xs={9.8}>
                  <Typography variant="h6" gutterBottom>
                     {title}
                  </Typography>
                  <div style={{ "display": "flex", "alignItems": "center", "marginBottom": "10px" }}>
                     <Badge label={t("Active")} color="greenBadge" noHover={true} />
                     <Badge label={t("Set up")} cursor="pointer" onClick={() => setShow(true)} />
                     <FormControl variant="standard" sx={{ m: 1, ml: 3, minWidth: 50 }} size="small">
                        <InputLabel id="type-select-label"></InputLabel>
                        <Select
                           labelId="type-select-label"
                           id="type-select"
                           name={providerId}
                           value={valueOrder}
                           onChange={onHandleChangeOrder}
                        >
                           {servicesOrder.map(order => <MenuItem key={order} value={order}>{order}</MenuItem>)}
                        </Select>
                     </FormControl>
                  </div>
                  <Typography gutterBottom>
                     {cost} {t("Cost per transaction")}
                  </Typography>
               </Grid>
            </Grid>
            <Divider my={6} />
            <Grid container spacing={3}>
               <Grid item xs={1}></Grid>
               <Grid item xs={11}>
                  <ul>
                     <li>SMTP</li>
                     <li>API</li>
                     <li>{t("Support")}</li>
                  </ul>
               </Grid>
            </Grid>
         </CardContent>
         <FormDialog {...{ providerId, initialProviderParameters, order: valueOrder, show, setShow, servicesOrder }} />
      </Card>
   );
}

async function getProviders(setProviders) {
   let response = await axios.get("masters/providers");
   setProviders(response.data);
}

function ServiceProviders() {
   const { t } = useTranslation();
   const [providers, setProviders] = useState([]);
   const [loading, setLoading] = useState(true);
   const [openSnackbar, setOpenSnackbar] = useState(false);
   const servicesOrder = ["Principal"];
   providers.forEach((p, i) => servicesOrder.push(`Backup${++i}`))
   servicesOrder.pop();
   //console.log(providers);
   
   useEffect(() => {
      (async () => {
         try {
            await getProviders(setProviders);
         }
         catch {
            console.error("Content no loaded");
         }
         
         setLoading(false);
      })()
   }, [])

   const handleChangeOrder = async (event) => {
      const id = event.target.name;
      const value = event.target.value;
      let prevProviderAccount = null;
      let providerAccount = null;

      if(providers.find(p => p.id === id).accountProviders.length) {
         setProviders(prevProviders => {
            let order = servicesOrder.indexOf(value);
            let prevprovider = prevProviders.find(p => p.accountProviders.length && p.accountProviders[0].order === order);
            let provider = prevProviders.find(p => p.id === id);
            
            if(prevprovider === undefined) {
               setOpenSnackbar(true);
               return prevProviders;
            }

            prevprovider.accountProviders[0].order = provider.accountProviders[0].order;
            provider.accountProviders[0].order = order;
            
            prevProviderAccount = prevprovider.accountProviders[0];
            providerAccount = provider.accountProviders[0];

            prevProviders = prevProviders.map(p => {
               if(p.id === prevprovider.id) {
                  return prevprovider;
               }

               if(p.id === provider.id) {
                  return provider;
               }

               return p;
            });

            return prevProviders;
         });
      }
      else {
         setOpenSnackbar(true);
      }

      if(prevProviderAccount !== null && providerAccount !== null) {
         await axios.put(`masters/accountProviders/${prevProviderAccount.id}`, { id: prevProviderAccount.id, order: prevProviderAccount.order });
         await axios.put(`masters/accountProviders/${providerAccount.id}`, { id: providerAccount.id, order: providerAccount.order });
      }
   }

   const handleCloseSnackbar = (event, reason) => {
		if (reason === 'clickaway') {
		  return;
		}
	
		setOpenSnackbar(false);
	};

   return (
      <React.Fragment>
         <Helmet title={t("Service Providers")} />
         <Typography variant="h3" gutterBottom display="inline">
            {t("Service Providers")}
         </Typography>

         <Breadcrumbs aria-label="Breadcrumb" mt={2}>
            <Typography>{t("Configurations")}</Typography>
         </Breadcrumbs>

         <Divider my={6} />
         <Typography variant="h4" gutterBottom display="block">
            Emails Relay
         </Typography>
         <Grid container spacing={6} mt={2}>
            {loading ? 
               <Grid item xs={12} lg={8} xl={9}> 
                  <Loader /> 
               </Grid>  :
               <>
                  {providers.map((provider, index) => 
                     <Grid item xs={8} lg={6.1} xl={3} key={provider.id}>
                        <ServiceCard 
                           providerId={provider.id}
                           title={provider.name} 
                           cost="RD$2.00"
                           valueOrder={servicesOrder[index]} 
                           onHandleChangeOrder={handleChangeOrder}
                           imageUrl={process.env.REACT_APP_PROXY_HOST + provider.imgUrl}
                           accountProviders={provider.accountProviders}
                           initialProviderParameters={provider.providerParameters}
                           servicesOrder={servicesOrder}
                        />
                     </Grid>   
                  )}
               </>
            }
         </Grid>
         <Snackbar open={openSnackbar} autoHideDuration={6000} onClose={handleCloseSnackbar} anchorOrigin={{ vertical: "top", horizontal: "right" }}>
				<Alert onClose={handleCloseSnackbar} severity="warning" sx={{ width: '100%' }}>
					{t("To change the order of a provider you must first configure it")}
				</Alert>
			</Snackbar>
      </React.Fragment>
   );
}

export default ServiceProviders;
