import React from "react";
import "./styles/index.css"
import { useRoutes } from "react-router-dom";
import { Provider } from "react-redux";
import { HelmetProvider, Helmet } from "react-helmet-async";
import { CacheProvider } from "@emotion/react";

import { ThemeProvider as MuiThemeProvider } from "@mui/material/styles";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import LocalizationProvider from "@mui/lab/LocalizationProvider";

import "./i18n";
import createTheme from "./theme";
import routes from "./routes";

import useTheme from "./hooks/useTheme";
import { store } from "./redux/store";
import createEmotionCache from "./utils/createEmotionCache";

import { AuthProvider } from "./contexts/Auth0Context";
import EnvironmentProvider from "./contexts/EnvironmentContext";

const clientSideEmotionCache = createEmotionCache();

function App({ emotionCache = clientSideEmotionCache }) {
   const content = useRoutes(routes);
   const { theme } = useTheme();

   return (
      <CacheProvider value={emotionCache}>
         <HelmetProvider>
            <EnvironmentProvider>
               <Helmet titleTemplate="%s | iPlus" defaultTitle="iPlus Technology" />
               <Provider store={store}>
                  <LocalizationProvider dateAdapter={AdapterDateFns}>
                     <MuiThemeProvider theme={createTheme(theme)}>
                        <AuthProvider>{content}</AuthProvider>
                     </MuiThemeProvider>
                  </LocalizationProvider>
               </Provider>
            </EnvironmentProvider>
         </HelmetProvider>
      </CacheProvider>
   );
}

export default App;
