import i18n from "i18next";
import { initReactI18next } from "react-i18next";

const resources = {
  en: {
    translation: {
      Search: "Search topics…",
      "Welcome back": "Welcome back",
      "We've missed you": "We've missed you",
      "Links": "Links",
      "Billing": "Billing",
      "Billings": "Billings",
      "BillNo": "Bill Num.",
      "Sent": "Sent",
      "Shipments": "Shipments",
      "Status": "Status",
      "Date": "Date",
      "Sender": "Sender",
      "Consignee": "Consignee",
      "Subject": "Title",
      "Transactions": "Transactions",
      "Documentations": "Documentations",
      "Introduction": "Introduction",
      "IntroductionBody": "The purpose of this technical documentation is to serve as a guide in the integration of third-party systems with the service",
      "TableOfContents": "Table of Contents",
      "TableOfContentsBody": "",
      "Configurations": "Configurations",
      "Comments": "Comments",
      "Comment": "Comment",
      "CreatedIn": "created in",
      "Action": "Action",
      "UsageParameters": "usage parameters",
      "MyUserOf": "My user of",
      "My": "My",
      "Active": "Active",
      "Inactive": "Inactive",
      "Edit": "Edit",
      "Details": "Details",
      "Delete": "Delete",
      "New": "New",
      "ApiKeyDetailTitle": "API key details",
      "SaveChangesBtn": "Save Changes",
      "Cancel": "Cancel",
      "Save": "Save",
      "Saving": "Saving",
      "SentThisMonth": "Sent this Month",
      "SentThisYear": "Sent this Year",
      "Of": "of",
      "Service Providers": "Service Providers",
      "Set up": "Set up",
      "Cost per transaction": "Cost per transaction",
      "Support": "support",
      "User": "User",
      "Password": "Password",
      "FromEmail": "From Email",
      "FromDisplay": "From Display",
      "SecretKey": "Secret Key",
      "SecreteKey": "Secret Key",
      "AccessKey": "Access Key",
      "ApiKey": "Api Key",
      "ApiUrl": "Api Url",
      "Profile": "Profile",
      "Subscription": "Subscription",
      "PaymentMethods": "Payment Methods",
      "SignOut": "Sign out",
      "MyAccount": "My account",
      "Account": "Account",
      "CreditCards": "Credit cards",
      "Expire": "Expire",
      "Card": "Card",
      "CardName": "Card name",
      "CardNumber": "Card number",
      "Basic": 'Basic',
      "Month": "Month",
      "Monthly": "mensuales",
      "ShipmentsUntil": "shipments until",
      "SupportViaTicket": "Support via ticket",
      "TaxReceipt": "Tax Receipt",
      "Amount": "Amount",
      "UploadPhoto": "Upload photo",
      "CompanyData": "company data",
      "CompanyName": "Company Name",
      "WebSite": "Web Site",
      "TaxId": "tax id",
      "Cancel your subscription and delete all data from your account in this service": "Cancel your subscription and delete all data from your account in this service",
      "BillingDetails": "Billing Details",
      "AccountDetails": "Account Details",
      "Name": "Name",
      "LastName": "Last Name",
      "Address": "Address",
      "Country": "Country",
      "Province": "Province",
      "City": "City",
      "PostalCode": "Postal Code",
      "Email": "Email",
      "MobilePhone": "Mobile Phone",
      "MonthlyBilling": "Monthly billing",
      "January": "January",
      "February": "February",
      "March": "March",
      "April": "April",
      "May": "May",
      "June": "June",
      "July": "July",
      "August": "August",
      "September": "September",
      "October": "October",
      "November": "November",
      "December": "December",
      "Alert": "Alert",
      "Are you sure you want to delete this resource?": "Are you sure you want to delete this resource?",
      "Agree": "Agree",
      "Changes were successfully saved": "Changes were successfully saved",
      "To change the order of a provider you must first configure it": "To change the order of a provider you must first configure it",
    },
  },
  es: {
    translation: {
      Search: "Buscar temas…",
      "Welcome back": "Bienvenido de nuevo",
      "We've missed you": "Te hemos extrañado",
      "Links": "Enlaces",
      "Billing": "Facturación",
      "Billings": "Facturas",
      "BillNo": "Factura No.",
      "Sent": "Enviados",
      "Shipments": "Envíos",
      "Status": "Estatus",
      "Date": "Fecha",
      "Sender": "Remitente",
      "Consignee": "Consignatario",
      "Subject": "título",
      "Transactions": "Transacciones",
      "Documentations": "Documentaciones",
      "Introduction": "Introducción",
      "IntroductionBody": "El propósito de esta documentación técnica es servir como guía en la integración de sistemas de terceros con el servicio",
      "TableOfContents": "Tabla de Contenido",
      "TableOfContentsBody": "",
      "Configurations": "Configuraciones",
      "Comments": "Comentarios",
      "Comment": "Comentario",
      "CreatedIn": "Creado en",
      "Action": "Acción",
      "UsageParameters": "Parámetros de uso",
      "MyUserOf": "Mi usuario de",
      "My": "Mi",
      "Active": "Activo",
      "Inactive": "Inactivo",
      "Edit": "Editar",
      "Details": "Detalles",
      "Delete": "Eliminar",
      "New": "Nuevo",
      "ApiKeyDetailTitle": "detalles de API key",
      "SaveChangesBtn": "Guardar Cambios",
      "Cancel": "Cancelar",
      "Save": "Guardar",
      "Saving": "Guardando",
      "SentThisMonth": "Enviados este Mes",
      "SentThisYear": "Enviados este Año",
      "Of": "de",
      "Service Providers": "Proveedores de Servicios",
      "Set up": "Configurar",
      "Cost per transaction": "Costo por transacción",
      "Support": "soporte",
      "User": "Usuario",
      "Password": "Contraseña",
      "FromEmail": "Desde el email",
      "FromDisplay": "Desde la pantalla",
      "SecretKey": "Clave Secreta",
      "SecreteKey": "Clave Secreta",
      "AccessKey": "Llave de Acceso",
      "ApiKey": "Clabe API",
      "ApiUrl": "Url de API",
      "Profile": "Perfil",
      "Subscription": "Suscripción",
      "PaymentMethods": "Métodos de Pago",
      "SignOut": "Cerrar Sesión",
      "MyAccount": "Mi cuenta",
      "Account": "Cuenta",
      "CreditCards": "Tarjetas de Crédito",
      "Expire": "Expira",
      "Card": "Tarjeta",
      "CardName": "Nombre de Tarjeta",
      "CardNumber": "Número de Tarjeta",
      "Basic": "Básico",
      "Month": "Mes",
      "Monthly": "mensuales",
      "ShipmentsUntil": "Envíos hasta",
      "SupportViaTicket": "Soporte vía ticket",
      "TaxReceipt": "Comprobante Fiscal",
      "Amount": "Monto",
      "UploadPhoto": "Subir Foto",
      "CompanyData": "Datos de commpañia",
      "CompanyName": "Nombre de Compañia",
      "WebSite": "Sitio Web",
      "TaxId": "Identificación del Impuesto",
      "Cancel your subscription and delete all data from your account in this service": "Cancela tu suscripción y elimina todos los datos de tu cuenta en este servicio",
      "BillingDetails": "Detalles de Facturación",
      "AccountDetails": "Detalles de Cuenta",
      "Name": "Nombre",
      "LastName": "Apellido",
      "Address": "Dirección",
      "Country": "Ciudad",
      "Province": "Provincia",
      "City": "Ciudad",
      "PostalCode": "Código Postal",
      "Email": "Dirección de Correo Electronico",
      "MobilePhone": "Teléfono Móvil",
      "MonthlyBilling": "Facturación Mensual",
      "January": "Enero",
      "February": "Febrero",
      "March": "Marzo",
      "April": "Abril",
      "May": "Mayo",
      "June": "Junio",
      "July": "Julio",
      "August": "Agosto",
      "September": "Septiembre",
      "October": "Octubre",
      "November": "Noviembre",
      "December": "Diciembre",
      "Alert": "Alerta",
      "Are you sure you want to delete this resource?": "Estas seguro que quieres eliminar este recurso?",
      "Agree": "Aceptar",
      "Changes were successfully saved": "Los cambios fueron guardados satisfactoriamente",
      "To change the order of a provider you must first configure it": "Para cambiar el orden de un proveedor primero debe configurarlo",
    },
  },
};

i18n.use(initReactI18next).init({
  resources,
  lng: "en",
  fallbackLng: "en",
  interpolation: {
    escapeValue: false,
  },
});
