import React from 'react';
import styled from "@emotion/styled";
import styles from "../styles/service-providers.module.css";
import { Chip as MuiChip } from "@mui/material";

const Chip = styled(MuiChip)`
  height: 20px;
  padding: 4px 5px;
  font-size: 85%;
  background-color: ${(props) =>
    props.theme.palette[props.color ? props.color : "primary"].light};
  color: ${(props) => props.theme.palette.common.white};
  margin-right: ${(props) => props.theme.spacing(2)};
  cursor: ${(props) => props.cursor};
    &:hover {
      background-color: ${(props) =>
        props.theme.palette[props.color ? props.color : "primary"].light};
    }

  span {
    padding-left: ${(props) => props.theme.spacing(2)};
    padding-right: ${(props) => props.theme.spacing(2)};
  }
`;

export default function Badge({ label, color, cursor = "default", onClick, noHover = false }) {
  return (
    <Chip {...{label, color, cursor, onClick}} className={noHover ? styles['chip-no-hover'] : ""} />
  )
}
