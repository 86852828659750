import {
  BarChart2,
  Activity,
  Settings,
  Book,
  } from "react-feather";
  
  
const pagesSection = [
  {
    href: "/dashboard",
    icon: BarChart2,
    title: "Dashboard",
    children: [
      {
        href: "/dashboard/emails-relay",
        title: "Emails Relay",
      },
      {
        href: "/dashboard/sms-relay",
        title: "SMS Relay",
      },
      {
        href: "/dashboard/push-notifications-relay",
        title: "Push Notifications Relay",
      },
    ],
  },
  {
    href: "/transactions",
    icon: Activity,
    title: "Transactions",
    children: [
      {
        href: "/transactions/emails-sent",
        title: "Emails Sent",
      },
      {
        href: "/transactions/sms-sent",
        title: "SMS Sent",
      },
      {
        href: "/transactions/push-notifications-sent",
        title: "Push Notifications Sent",
      },
    ],
  },
  {
    href: "/settings",
    icon: Settings,
    title: "Settings",
    children: [
      {
        href: "/settings/api-keys",
        title: "API Keys",
      },
      {
        href: "/settings/service-providers",
        title: "Service Providers",
      },
    ],
  },
  {
    href: "/documentations",
    icon: Book,
    title: "Documentations",
    children: [
      {
        href: "/documentations/emails-relay",
        title: "Emails Relay",
      },
      {
        href: "/documentations/sms-relay",
        title: "SMS Relay",
      },
      {
        href: "/documentations/push-notifications-relay",
        title: "Push Notifications Relay",
      },
    ],
  },
];
  
const navItems = [
  {
    title: "Páginas",
    pages: pagesSection,
  },
];
  
export default navItems;
  