import React, { useEffect, useState, useContext } from 'react'
import { useTranslation } from "react-i18next";
import PropTypes from 'prop-types';
import { EnvironmentContext } from "../contexts/EnvironmentContext";
import axios from "../utils/axios";
import LoadingButton from '@mui/lab/LoadingButton';

import {
	Button,
	Dialog,
	DialogActions,
	DialogContent,
	TextField,
	Tabs,
	Tab,
	Box,
	Snackbar,
	Alert as MuiAlert
} from "@mui/material";

function TabPanel(props) {
	const { children, value, index, ...other } = props;

	return (
		<div
			role="tabpanel"
			hidden={value !== index}
			id={`simple-tabpanel-${index}`}
			aria-labelledby={`simple-tab-${index}`}
			{...other}
		>
			{value === index && (
				<Box sx={{ p: 3 }}>
					{children}
				</Box>
			)}
		</div>
	);
}

TabPanel.propTypes = {
	children: PropTypes.node,
	index: PropTypes.number.isRequired,
	value: PropTypes.number.isRequired,
};

function a11yProps(index) {
	return {
		id: `simple-tab-${index}`,
		'aria-controls': `simple-tabpanel-${index}`,
	};
}

const Alert = React.forwardRef(function Alert(props, ref) {
	return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

function FormDialog({ providerId, initialProviderParameters, order, show, setShow, servicesOrder }) {
	const { t } = useTranslation();
	const { environment } = useContext(EnvironmentContext);
	const [providerParameters, setProviderParameters] = useState(initialProviderParameters);
	const [open, setOpen] = useState(show);
	const [tab, setTab] = useState(0);
	const [openSnackbar, setOpenSnackbar] = useState(false);
	const [saving, setSaving] = useState(false);

	useEffect(() => {
		setOpen(show);
	}, [show])

	const closeDialog = () => {
		setOpen(false);
		setShow(false);
		setSaving(false);
	}

	const handleChange = (event, newTab) => {
		setTab(newTab);
	};

	const saveProviderParameters = async () => {
		const accountProvider = {
			"environmentId": environment,
			"providerId": providerId,
			"order": servicesOrder.indexOf(order),
			"createAccountProviderParameterDtos": providerParameters.map(p => 
				{
					return {
						"environmentId": environment,
						"providerId": providerId,
						"providerParameterId": p.id,
						"value": p.accountProviderParameters.length ? p.accountProviderParameters[0].value : p.value,
					}
				}
			)
		}

		setSaving(true);
		await axios.post("masters/accountProviders", accountProvider);
		setOpenSnackbar(true);
		closeDialog();
	}

	const handleCloseSnackbar = (event, reason) => {
		if (reason === 'clickaway') {
		  return;
		}
	
		setOpenSnackbar(false);
	};

	const handleChangeParameters = (event) => {
        const name = event.target.name;
        const value = event.target.value;
		
        setProviderParameters(values => {
			let parameter = values.find(v => v.id === name);
			
			if(parameter.accountProviderParameters.length) {
				parameter.accountProviderParameters[0].value = value;
			}
			else {
				parameter.value = value;
			}

			const index = values.findIndex(v => v.id === name);
			values = values.map((v, i) => i === index ? parameter : v);
			
			return values
		});
    }

	return (
		<div>
			<Dialog
				open={open}
				onClose={closeDialog}
				aria-labelledby="form-dialog-title"
				fullWidth={true}
        		maxWidth="sm"
			>
				<DialogContent>
					<Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
						<Tabs value={tab} onChange={handleChange} aria-label="basic tabs example">
							<Tab label="SMTP" {...a11yProps(0)} />
							<Tab label="API" {...a11yProps(1)} />
						</Tabs>
					</Box>
						<TabPanel value={tab} index={0}>
							<TextField
								margin="dense"
								id="smtp_user"
								label={t("User")}
								variant="standard"
								fullWidth
								onChange={handleChangeParameters}
								name={providerParameters.find(p => p.serviceTypeId === "Smtp" && p.name === "User")?.id}
								value={providerParameters.find(p => p.serviceTypeId === "Smtp" && p.name === "User")?.accountProviderParameters.length ? 
									providerParameters.find(p => p.serviceTypeId === "Smtp" && p.name === "User")?.accountProviderParameters[0].value : 
									providerParameters.find(p => p.serviceTypeId === "Smtp" && p.name === "User")?.value}
							/>
							<TextField
								margin="dense"
								id="smtp_password"
								label={t("Password")}
								variant="standard"
								fullWidth
								onChange={handleChangeParameters}
								name={providerParameters.find(p => p.serviceTypeId === "Smtp" && p.name === "Pwd")?.id}
								value={providerParameters.find(p => p.serviceTypeId === "Smtp" && p.name === "Pwd")?.accountProviderParameters.length ? 
									providerParameters.find(p => p.serviceTypeId === "Smtp" && p.name === "Pwd")?.accountProviderParameters[0].value : 
									providerParameters.find(p => p.serviceTypeId === "Smtp" && p.name === "Pwd")?.value}
							/>
							<TextField
								margin="dense"
								id="smtp_fromEmail"
								label={t("FromEmail")}
								variant="standard"
								fullWidth
								onChange={handleChangeParameters}
								name={providerParameters.find(p => p.serviceTypeId === "Smtp" && p.name === "FromEmail")?.id}
								value={providerParameters.find(p => p.serviceTypeId === "Smtp" && p.name === "FromEmail")?.accountProviderParameters.length ? 
									providerParameters.find(p => p.serviceTypeId === "Smtp" && p.name === "FromEmail")?.accountProviderParameters[0].value : 
									providerParameters.find(p => p.serviceTypeId === "Smtp" && p.name === "FromEmail")?.value}
							/>
							<TextField
								margin="dense"
								id="smtp_fromTemplate"
								label={t("FromDisplay")}
								variant="standard"
								fullWidth
								onChange={handleChangeParameters}
								name={providerParameters.find(p => p.serviceTypeId === "Smtp" && p.name === "FromDisplay")?.id}
								value={providerParameters.find(p => p.serviceTypeId === "Smtp" && p.name === "FromDisplay")?.accountProviderParameters.length ? 
									providerParameters.find(p => p.serviceTypeId === "Smtp" && p.name === "FromDisplay")?.accountProviderParameters[0].value : 
									providerParameters.find(p => p.serviceTypeId === "Smtp" && p.name === "FromDisplay")?.value}
							/>
						</TabPanel>
						<TabPanel value={tab} index={1}>
							{providerParameters.filter(provider => provider.serviceTypeId === "Api").map(provider => {
								return <TextField
											key={provider.id}
											margin="dense"
											id={`api_${provider.name.toLowerCase()}`}
											label={t(provider.name)}
											variant="standard"
											fullWidth
											onChange={handleChangeParameters}
											name={provider.id}
											value={provider.accountProviderParameters.length ? provider.accountProviderParameters[0].value : provider.value} 
										/>
							})}
						</TabPanel>
				</DialogContent>
				<DialogActions>
					<Button variant="outlined" onClick={closeDialog} color="primary">
						{t("Cancel")}
					</Button>
					<LoadingButton
						onClick={saveProviderParameters}
						loading={saving}
						variant="contained"
					>
						{t("SaveChangesBtn")}
					</LoadingButton>
				</DialogActions>
			</Dialog>
			<Snackbar open={openSnackbar} autoHideDuration={6000} onClose={handleCloseSnackbar} anchorOrigin={{ vertical: "top", horizontal: "right" }}>
				<Alert onClose={handleCloseSnackbar} severity="success" sx={{ width: '100%' }}>
					{t("Changes were successfully saved")}
				</Alert>
			</Snackbar>
		</div>
	);
}

export default FormDialog;