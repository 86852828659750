import React from "react";
import styled from "@emotion/styled";
import Badge from "../../components/Badge";
import { Helmet } from "react-helmet-async";
import { useTranslation } from "react-i18next";

import {
  CardContent,
  Grid,
  Breadcrumbs as MuiBreadcrumbs,
  Card as MuiCard,
  Divider as MuiDivider,
  Typography,
} from "@mui/material";
import { spacing } from "@mui/system";

const Card = styled(MuiCard)(spacing);
const Divider = styled(MuiDivider)(spacing);
const Breadcrumbs = styled(MuiBreadcrumbs)(spacing);

function SubscriptionCard({ title, isActive, sendLimit }) {
  const { t } = useTranslation();
  
  return (
    <Card mb={6}>
      <CardContent>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Typography variant="h6" gutterBottom>
              {title}<span style={{ color: "#999", "textTransform": "lowercase", "fontSize": ".8rem" }}> /{t("Month")}</span>
            </Typography>
            <Typography variant="body" gutterBottom>
              <div style={{ "margin": "10px 0" }}>
                {isActive ?  <Badge label={t("Active")} color="greenBadge" /> : <Badge label="Upgrade" cursor="pointer" />}
              </div>
            </Typography>
            <Typography gutterBottom variant="body">
              {t("ShipmentsUntil")} {sendLimit} {t("Monthly")}
            </Typography>
          </Grid>
        </Grid>
        <Divider my={6} />
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Typography gutterBottom variant="body" display="block">
              SMTP Relay
            </Typography>
            <Typography gutterBottom variant="body" display="block">
              API
            </Typography>
            <Typography gutterBottom variant="body" display="block">
              {t("SupportViaTicket")}
            </Typography>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
}

function Subscription() {
  const { t } = useTranslation();

  return (
    <React.Fragment>
      <Helmet title={t("Subscription")} />
      <Typography variant="h3" gutterBottom display="inline">
        {t("Subscription")}
      </Typography>

      <Breadcrumbs aria-label="Breadcrumb" mt={2}>
        <Typography>{t("MyAccount")}</Typography>
      </Breadcrumbs>

      <Divider my={6} />
      <Typography variant="h4" gutterBottom display="block">
        Emails Relay
      </Typography>

      <Grid container spacing={6} mt={2} mb={2}>
        <Grid item xs={8} lg={6.1} xl={3}>
          <SubscriptionCard title={t("Basic") + " | US$ 100"} isActive={true} sendLimit="100,000 emails" />
        </Grid>
        <Grid item xs={8} lg={6.1} xl={3}>
          <SubscriptionCard title="Standard | US$ 200" isActive={false} sendLimit="500,000 emails" />
        </Grid>
        <Grid item xs={8} lg={6.1} xl={3}>
          <SubscriptionCard title="Premium | US$ 400" isActive={false} sendLimit="100,000,000 emails" />
        </Grid>
      </Grid>

      <Typography variant="h4" gutterBottom display="block">
        SMS Relay
      </Typography>

      <Grid container spacing={6} mt={2} mb={2}>
        <Grid item xs={8} lg={6.1} xl={3}>
          <SubscriptionCard title={t("Basic") + " | US$ 100"} isActive={true} sendLimit="100,000 sms" />
        </Grid>
        <Grid item xs={8} lg={6.1} xl={3}>
          <SubscriptionCard title="Standard | US$ 200" isActive={false} sendLimit="500,000 sms" />
        </Grid>
        <Grid item xs={8} lg={6.1} xl={3}>
          <SubscriptionCard title="Premium | US$ 400" isActive={false} sendLimit="100,000,000 sms" />
        </Grid>
      </Grid>

      <Typography variant="h4" gutterBottom display="block">
        Push Notifications Relay
      </Typography>

      <Grid container spacing={6} mt={2} mb={2}>
        <Grid item xs={8} lg={6.1} xl={3}>
          <SubscriptionCard title={t("Basic") + " | US$ 100"} isActive={true} sendLimit="100,000 push notifications" />
        </Grid>
        <Grid item xs={8} lg={6.1} xl={3}>
          <SubscriptionCard title="Standard | US$ 200" isActive={false} sendLimit="500,000 push notifications" />
        </Grid>
        <Grid item xs={8} lg={6.1} xl={3}>
          <SubscriptionCard title="Premium | US$ 400" isActive={false} sendLimit="100,000,000 push notifications" />
        </Grid>
      </Grid>
    </React.Fragment>
  );
}

export default Subscription;
